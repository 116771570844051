import c3api from "@/c3api";
import { ref } from "vue";
import { useToast } from "vue-toastification";

export default function useBillingRates() {
  const ratesLoading = ref(false);

  const rates = ref([]);
  const rate = ref({});

  const toast = useToast();

  const fetchRates = async (urlParams = null) => {
    try {
      ratesLoading.value = true;
      const response = await c3api.get("/rates", { params: urlParams });
      return (rates.value = response.data.data);
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      ratesLoading.value = false;
    }
  };

  const fetchRateWithId = async (id) => {
    try {
      ratesLoading.value = true;
      const response = await c3api.get(`/rates/${id}`);
      return (rate.value = response.data.data);
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      ratesLoading.value = false;
    }
  };

  const updateRates = async (id, data) => {
    try {
      ratesLoading.value++;
      await c3api.patch(`/rates/${id}`, data);
    } catch (error) {
      toast.error(error.response.data.data.join(". "));
    } finally {
      ratesLoading.value--;
    }
  };

  return {
    rates,
    fetchRates,
    updateRates,
    rate,
    fetchRateWithId
  };
}
